import React from 'react';
import Header from './AppHeader';
import NotLoggedIn from './NotLoggedIn';

import { useUserCon } from '../contexts/UserContext';
import { useImage } from '../contexts/ImageContext';
import { useRender } from '../contexts/RenderContext';
import TypingText from './typeaheadBot';

const MainComponent = ({
  setOpenHelper,
  setOpenProHelper,
  firstSignup,
  signInMessage,
  emailUploading,
  submitEmailForSignIn,
  selectFile,
  checkIfLoggedIn,
  uploading,
  currentRenders

  // All the required props and states
}) => {
  const { user, subscription, userDetails } = useUserCon();
  const { imageUrl } = useImage();
  const { hasMoreRenders } = useRender();

  return (
    <div className="flex flex-col items-center gap-2 sm:gap-4 max-w-2xl mx-2 mb-2 sm:mx-auto sm:mb-6">
      <Header
        user={user}
        subscription={subscription}
        hasMoreRenders={hasMoreRenders}
        setOpenHelper={setOpenHelper}
        setOpenProHelper={setOpenProHelper}
        uploading={uploading}
        currentRenders={currentRenders}
      />
    </div>
  );
};

export default MainComponent;
